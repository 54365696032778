<template>
    <a-spin :spinning="loading">
        <div class="statistic-wrap list_grid grid-cols-1 2xl:grid-cols-2">
            <div v-if="fundingSourceStatistic.length" class="statistic_item">
                <div class="statistic_item__total">
                    <div class="label">Всего: </div>
                    <div class="value">
                        <span class="value__count">{{fundingSourceStatistic[0].total_sum}}</span>
                        <span class="value__measure">млн.тг</span>
                    </div>
                </div>
                <div class="st-grid">
                    <div v-for="(item, index) in fundingSourceStatistic" :key="index" class="item">
                        <div class="progress">
                            <div class="label">
                                <div>{{ item.source.short_name }}</div>
                                <div>{{ item.source_sum }} млн.тг</div>
                            </div>
                            <div class="pb-wrap">
                                <a-progress
                                    class="custom-progress"
                                    :class="`trail-color-${index}`"
                                    :percent="Number(item.source_percent)"
                                    :show-info="false"
                                    :strokeWidth="21.45"
                                    :strokeColor="strokeColor(index)" />
                                <div class="progress-text" :class="[
                                    {white: index === 0 || index === 3},
                                    {black: index === 1 || index === 2}
                                ]">
                                    {{ item.source_percent }} %
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="categoryStatistic.length" class="statistic_item">
                <div class="statistic_item__total">
                    <div class="label">Всего: </div>
                    <div class="value">
                        <span class="value__count">{{categoryStatistic[0].total_count}}</span>
                        <span class="value__measure">{{ projectWord(Number(categoryStatistic[0].total_count)) }}</span>
                    </div>
                </div>
                <div class="st-grid">
                    <div v-for="(item, index) in categoryStatistic" :key="index" class="item">
                        <div class="progress">
                            <div class="label">
                                <div>{{ item.category.name }}</div>
                                <div>{{ item.category_count }}</div>
                            </div>
                            <div class="pb-wrap">
                                <a-progress
                                    class="custom-progress"
                                    :class="`trail-color-${index}`"
                                    :percent="Number(item.category_percent)"
                                    :show-info="false"
                                    :strokeWidth="21.45"
                                    :strokeColor="strokeColor(index)" />
                                <div class="progress-text" :class="[
                                    {white: index === 0 || index === 3},
                                    {black: index === 1 || index === 2},
                                ]">
                                    {{ item.category_percent }} %
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a-spin>
</template>
<script>
import eventBus from '@/utils/eventBus'

export default {
    name: 'Statistic',
    data() {
        return {
            categoryStatistic: [],
            fundingSourceStatistic: [],
            loading: false,
            page_name: 'invest_project_list',
            model: 'invest_projects_info.InvestProjectInfoModel',
            strokeColors: [
                '#F94A1D',
                '#80CC33',
                '#33CCCC',
                '#8941D0'
            ],
        }
    },
    created() {
        this.getStatistic()
    },
    mounted() {
        eventBus.$on(`update_filter_${this.model}_${this.page_name}`, () => {
            this.statisticReload()
        })
        eventBus.$on('update_invest_project_statistic', () => {
            this.statisticReload()
        })
    },
    beforeDestroy() {
        eventBus.$off(`update_filter_${this.model}_${this.page_name}`)
        eventBus.$off('update_invest_project_statistic')
    },
    methods: {
        strokeColor(index) {
            return this.strokeColors[index]
        },
        projectWord(count) {
            const lastDigit = count % 10
            const lastTwoDigits = count % 100

            if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
                return 'проектов'
            }
            if (lastDigit === 1) {
                return 'проект'
            }
            if (lastDigit >= 2 && lastDigit <= 4) {
                return 'проекта'
            }
            return 'проектов'
        },
        statisticReload() {
            this.categoryStatistic = []
            this.fundingSourceStatistic = []
            this.getStatistic()
        },
        async getStatistic() {
            this.loading = true
            const params = {
                page_name: this.page_name
            }
            const categoryStatistics = new Promise((resolve, reject) => {
                this.$http.get('/invest_projects_info/category_statistics/', {
                    params: params
                }).then((response) => resolve(response))
            })
            const fundingSourceStatistics = new Promise((resolve, reject) => {
                this.$http.get('/invest_projects_info/funding_source_statistics/', {
                    params: params
                }).then((response) => resolve(response))
            })
            Promise.all([categoryStatistics, fundingSourceStatistics])
                .then(([categoryStatistics, fundingSourceStatistics]) => {
                    this.categoryStatistic = categoryStatistics.data
                    this.fundingSourceStatistic = fundingSourceStatistics.data
                })
                .catch(error => {
                    console.log(error)
                    this.$message.error('Не удалось получить данные статистики')
                })
                .finally(() => {
                    this.loading = false
                })
        },
    }
}
</script>
<style lang="scss" scoped>
.statistic-wrap{
    width: 100%;
    margin-bottom: 70px;
    min-height: 88px;
    .statistic_item{
        border: 1px solid var(--border2);
        border-radius: var(--borderRadius);
        padding: 10px;
        display: flex;
        gap: 14px;
        align-items: end;
        .st-grid {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(4, 1fr);
            align-items: end;
            gap: 14px;
            @media (max-width: 1000px) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
            }
        }
        &__total{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .label{
                color: #000000;
                font-size: 16px;
                font-weight: 400;
                opacity: 0.6;
            }
            .value{
                display: flex;
                flex-wrap: nowrap;
                align-items: end;
                &__count, &__measure {
                    font-weight: 400;
                }
                &__count{
                    font-size: 32px;
                    line-height: 32px
                }
                &__measure{
                    font-size: 16px;
                    margin-left: 8px;
                }
            }
        }
        .item::v-deep {
            flex-grow: 1;
            .progress{
                .label{
                    font-size: 14px;
                    font-weight: 400;
                    color: #000000;
                    text-align: center;
    
                }
                position: relative;
                display: inline-block;
                width: 100%;
                .pb-wrap {
                    position: relative;
                    .progress-text {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 10px;
                        font-weight: 400;
                    }
                    .white{
                        color: #ffffff;
                    }
                    .black{
                        color: #000000;
                    }
                }
                
            }
            .ant-progress-inner {
                border-radius: 4px;
            }
            .ant-progress-bg {
                border-radius: 4px !important;
            }
        }
        .trail-color-0::v-deep {
            .ant-progress-inner {
                background-color: #F94A1D33;
            }
        }
        .trail-color-1::v-deep {
            .ant-progress-inner {
                background-color: #80CC3333;
            }
        }
        .trail-color-2::v-deep {
            .ant-progress-inner {
                background-color: #33CCCC33;
            }
        }
        .trail-color-3::v-deep {
            .ant-progress-inner {
                background-color: #8941D033;
            }
        }
    }
}
.list_grid{
    display: grid;
    gap: 15px;
    @media (min-width: 1700px) {
        gap: 30px;
    }
}
</style>